import '../styles/globals.css';
import React from 'react';
import Head from 'next/head';
// import { useRouter } from 'next/router';
import { ThemeProvider } from 'next-themes';
import 'normalize.css';
import 'lib/styles/_base.scss';
import { ViewportProvider } from 'utils/context/ViewportContextProvider';
import reportAccessibility from 'utils/reportAccessibility.ts';
import '@contentful/live-preview/style.css';
import Script from 'next/script';
import { fontsClassNames } from 'lib/fonts/mapping';

const App = (props) => {
    const { Component, pageProps, clientDevice } = props;

    return (
        <ThemeProvider>
            <ViewportProvider clientDevice={clientDevice}>
                <Head>
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, maximum-scale=5.0"
                    />
                </Head>
                <div className={fontsClassNames}>
                    <Component {...pageProps} />
                </div>
                <Script
                    src="https://cdn.userway.org/widget.js"
                    data-account="POwdDgCYwA"
                    data-trigger="accessibilityButton"
                    strategy="afterInteractive"
                />
            </ViewportProvider>
        </ThemeProvider>
    );
};

reportAccessibility(React);

export default App;
